.app-card{
    cursor: pointer;
    overflow: visible !important;
    min-height: 120px;
    height: 100%;
    padding: 0 15px;

    .mw-100pc-130px{
        max-width: calc(100% - 130px);
    }

    .mw-130{
        max-width: 130px;
    }
    .action-button{
        font-size: 14px;
    }

    .app-card-name{
        color: #fff;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 500;
        margin-bottom: 3px;
        padding-top: 5px;
    }

    .app-card-description{
        color: #fff;
        font-size: 16px;
        padding-top: 15px;
    }

    .app-icon{
        margin-top: -40px;  
        border-radius: 8px;
        transition: all 0.15s linear;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(33, 54, 33, 0.4);
    }

    &:hover{
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 7px 5px -5px rgba(33, 54, 33, 0.4);
        .app-icon{
            margin-top: -50px;
            margin-bottom: 10px;
            border-radius: 8px;
            transition: all 0.15s linear;
        }
    }

    .btn-view-more{
        font-size: 14px;  
        color: #fff;
        text-transform: lowercase; 
    }

    .app-card-header{
        border-radius: 4px 4px 0 0;
        height: 45px;
    }

    .app-card-body{
        border-radius: 0 0 4px 4px;
        height: 100px;        
    }
}