.app-card-col{
    padding: 15px;
    margin-top: 15px;
}

@media (min-width: 1600px){
.col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
}
}

.app-accordion-body{
    color: #fff !important;
}